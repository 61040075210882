.about {
  position: relative;
  min-height: 100vh;
  background-image: url("../../assets/image/IMG_8436\ copy2.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
}

.about::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 90%,
    var(--Tre-color) 100%
  );
  z-index: 0;
}
.about .section_container{
  position: relative;
  z-index: 100;
}
.about::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 80%,
    var(--Tre-color) 100%
  );
}
.about h1 {
  color: var(--Pr-color);
}
.about .about_body {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  align-items: center;
  margin: auto;
  height: 100%;
  transform: translate(0%, 2%);
  max-width: 1200px;
}
.about .about_body .about_body_l {
  flex: 0.4;
}
.about .about_body .about_body_r {
  flex: 0.6;
}
.about .about_body .about_body_r h1 {
  font-size: 35px;
  text-transform: capitalize;
}
.about .about_body .about_body_r p {
  margin: 20px 0;
  line-height: 2;
  font-size: 15px;
  color: var(--Sec-color);
}
.about .about_body .about_body_l img {
  width: 68%;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .section_container {
    padding: 3rem 1rem;
  }
  .about {
    background-size: cover;
    height: auto;
    padding: 3rem 0;
  }
  .about::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 90%,
      var(--Tre-color) 100%
    );
  }
  .about .about_body .about_body_l {
    text-align: center;
    margin-bottom: 50px;
    background: radial-gradient();
  }
  .about .about_body .about_body_l img {
    width: 75%;
  }
  .about .about_body {
    flex-direction: column;
    justify-content: center;
    max-width: 90%;
    margin: auto;
  }
}
