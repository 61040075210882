.nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0 6rem;
  height: 80px;
  z-index: 1000;
  transition: 0.2s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
.nav.scrollClassName {
  background-color: transparent;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(10px);
  transition: background-color 0.2s ease-in-out;
}
nav {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
nav .logo h1 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--Pr-color);
  width: 20%;
  font-size: larger;
}
nav .logo {
  flex: 0.4;
}
nav .logo img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}
nav .logo i {
  display: none;
}

nav .menu {
  flex: 0.5;
}
nav .menu ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

nav .menu ul li a {
  position: relative;
  cursor: pointer;
  opacity: 1;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 15px;
  color: var(--Sec-color);
  padding: 5px 10px;
  transition: color 0.25s ease-in-out;
}
nav .menu ul.ar li a.active::before {
  background-color: var(--Pr-color);
  scale: 0;
}
nav .menu ul.ar li a::before {
  scale: 0;
}
nav .menu ul.ar li a:hover {
  background-color: var(--Pr-color);
  border-radius: 10px;
}
nav .menu ul.ar li a:hover::before {
  scale: 0;
}
nav .menu ul.ar li a.active {
  background-color: var(--Pr-color);
  border-radius: 10px;
}

nav .menu ul li a::befdore {
  position: absolute;
  content: "";
  inset: 0;
  background-color: var(--Pr-color);
  scale: 1 0;
  z-index: -1;
  right: 0;
  transition: 0.45s;
  border-radius: 10px;
  transition: transform 0.45s ease-in-out;
}
nav .menu ul li a:hover {
  color: var(--Sec-color);
  background-color: var(--Pr-color);
  border-radius: 10px;
}
nav .menu ul li a:hover::befdore {
  scale: 1 1;
}
nav .menu ul li a.active {
  opacity: 1;
  color: var(--Sec-color);
  text-transform: uppercase;
  background-color: var(--Pr-color);
  border-radius: 10px;
}
nav .menu ul li a.active::bdefore {
  scale: 1 1;
  color: var(--Sec-color);
}

.nav .logo_menu {
  flex: 0.6;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
nav .menu ul select {
  padding: 6px 12px;
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  color: var(--Sec-color);
  font-size: 14px;
  border-radius: 12px;
}
nav .menu ul select option {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  font-size: 16px;
  text-align: center;
  font-family: "Cairo";
  font-weight: 600;
}
nav .menu ul select option:hover {
  background-color: var(--Pr-color);
}
nav .p-component {
  padding: 8px 20px;
  gap: 10px;
  color: var(--Sec-color);
}
nav .pi{
  color: var(--Sec-color);
}
nav .p-togglebutton.p-highlight .p-button {
  border-color: transparent;
  background-color: var(--Pr-color);
}
@media (max-width: 767px) {
  .nav {
    height: 80px;
    padding: 2rem 1rem;
  }
  nav {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  nav .logo {
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: center;
    /* margin-bottom: 15px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav .logo img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
  nav .logo i {
    display: block;
    color: var(--Sec-color);
    font-size: 30px;
    cursor: pointer;
  }
  nav .logo h1 {
    font-size: 24px;
  }
  .nav .menu {
    padding-top: 40px;
    width: 0;
    visibility: collapse;
    opacity: 0;
    display: none;
    transform: translateY(-500px);
    background-color: transparent !important;
    transition: transform 0.5s ease-in-out;
  }
  .nav .menu.active {
    width: 100vw;
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translateY(32px);
    background-color: var(--Tre-color) !important;
    backdrop-filter: blur(50px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
  }
  .nav.scrollClassName .menu {
    background-color: var(--Tre-color) !important;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    transition: transform 0.5s ease-in-out;
  }
  nav .menu ul {
    flex-direction: column;
    justify-content: space-between;
  }
  nav .menu ul li {
    margin-bottom: 30px;
  }
  nav .menu ul li a {
    font-size: 18px;
    padding: 10px 20px;
  }
  nav .menu ul select {
    margin-bottom: 20px;
  }
}
