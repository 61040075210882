.contact {
  padding: 3rem 4rem;
  background-position: 100%;
  background-size: 100%;
  background-attachment: fixed;
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.85) 5%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../../assets/image/download.jpeg");
}
.contact_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.contact_b_l h2 {
  font-size: 60px;
  color: var(--Pr-color);
  letter-spacing: 5px;
  margin-bottom: 10px;
}
.contact_b_l p {
  font-size: 12px;
  color: var(--Sec-color);
  opacity: 0.5;
  letter-spacing: 5px;
}
.infor {
  position: relative;
  height: 100%;
  padding: 0 2rem;
  margin: 50px 0;
}
.infor.right::before {
  right: 0;
}
.infor::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  border-radius: 10px;
  background-color: var(--Pr-color);
}
.infor .item {
  padding: 10px 0 50px;
  display: flex;
  align-items: center;
}
.infor .item i {
  font-size: 30px;
  color: var(--Pr-color);
  margin-right: 20px;
}
.infor .item label {
  color: var(--Sec-color);
  opacity: 0.8;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 2px;
}
.infor .item:last-child {
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .section_container {
    padding: 3rem 0;
  }
  .contact {
    height: auto;
    background-size: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 1rem 1rem;
  }
  .contact_b_l h2 {
    font-size: 50px;
  }
  .contact_b_l p {
    text-align: center;
  }
}
.contact_b_l {
  flex: 0.5;
}
.contact_b_r {
  flex: 0.5;
  margin-top: 50px;
}
.contact_b_r input,
.contact_b_r textarea {
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  color: var(--Sec-color);
  padding: 10px 20px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  resize: none;
  width: 100%;
}
.contact_b_r .child_group {
  display: flex;
  gap: 0 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contact_body {
    margin-top: 0px;
    flex-direction: column;
  }
  .contact_b_r {
    margin-top: 40px;
    width: 100%;
  }
  .contact_b_l {
    width: 100%;
  }
  .contact_b_r .child_group:first-child {
    flex-direction: column;
    width: 100%;
    gap: 15px 0;
  }
}
.contact_b_r .child_group button {
  background-color: var(--Pr-color);
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 12px;
  color: var(--Sec-color);
  cursor: pointer;
}
.contact_body .social_media {
  display: flex;
  align-items: center;
}

.contact_body .social_media a {
  margin-right: 20px;
}
.contact_body .social_media i {
  color: var(--Pr-color);
}
.Btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
}

.svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid var(--Pr-color);
}
