.MyBusiness {
  background-color: var(--Tre-color);
  background-image: url("../../assets/image/IMG_8436\ copy2.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  padding: 3rem 4rem;
}
/* .MyBusiness::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 90%,
    var(--Tre-color) 100%
  );
} */
.MyBusiness::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 90%,
    var(--Tre-color) 100%
  );
}
.MyBusiness .section_container {
  position: relative;
  z-index: 100;
}
.MyBusiness_body {
  color: var(--Sec-color);
}

.MyBusiness_body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 5;
  border-radius: 5px;
}
.MyBusiness_body .img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  position: relative;
  z-index: 2;
  border: 4px solid var(--Pr-color);
  border-radius: 10px;
}
.MyBusiness_body .img::befsore {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid var(--Pr-color);
  top: 8px;
  left: -8px;
  z-index: 1;
  border-radius: 5px;
}
.MyBusiness_body .card {
  position: relative;
  z-index: 6;
}
.MyBusiness_b {
  display: flex;
  align-items: flex-start;
  z-index: 5;
}

.MyBusiness_b .content {
  background-color: transparent;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(100px);
  width: 60%;
  margin: 0 10px;
  position: relative;
  border: 2px solid var(--Pr-color);
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  z-index: 10;
}
.MyBusiness_b .content h2 {
  color: var(--Pr-color);
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 26px;
  text-transform: uppercase;
  
}
.MyBusiness_b .content p {
  color: var(--Sec-color);
  line-height: 1.5;
  font-size: 14px;
  text-transform: capitalize;
  
}
.MyBusiness_b .content::befosre {
  position: absolute;
  content: "";
  background-color: transparent;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(100px);
  width: 100%;
  height: 100%;
  top: 8px;
  left: 8px;
  z-index: -1;
  border-radius: 10px;
}
.MyBusiness_b_l {
  padding: 20px;
}
.MyBusiness_b_r {
  direction: rtl;
  padding: 20px;
}
.MyBusiness_b_r .content {
  direction: ltr;
}
.MyBusiness_b_r .img::before {
  left: unset;
  right: -8px;
}
.MyBusiness_b_r .content::before {
  left: unset;
  right: 8px;
}
@media (max-width: 767px) {
  .section_container {
    padding: 3rem 0;
  }
  .MyBusiness {
    background-size: cover;
    height: auto;
    padding: 3rem 0;
  }
  .MyBusiness_b {
    flex-direction: column;
    align-items: center;
  }
  .MyBusiness_b .content {
    width: 100%;
    margin: 25px 0;
  }
}

.loader {
  color: var(--Pr-color);
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
