.home {
  position: relative;
  top: 0;
  background-image: url("../../assets/image/IMG_8436\ copy2.jpg");
  /* background-image: url("../../assets/image/IMG_84361.jpg"); */
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
  height: calc(100vh - 60px);
}
.home img {
  position: absolute;
  right: 80px;
  bottom: -50px;
  width: 38%;
  height: 100%;
  z-index: 0;
}
.home::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 80%,
    var(--Tre-color) 100%
  );
}
.home .home_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  height: 60%;
  transform: translate(-40%, 50%);
  max-width: 600px;
  position: relative;
  z-index: 5;
}

.home .home_body h1 {
  color: var(--Pr-color);
  font-size: 35px;
  margin-bottom: 20px;
}
.home .home_body p {
  font-size: 14px;
  color: var(--Sec-color);
  /* opacity: 0.8; */
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 15px;
}

.home .home_footer {
  position: absolute;
  content: "";
  z-index: 1;
  color: var(--Pr-color);
  font-weight: 500;
  background-color: transparent;
  bottom: 120px;
  right: 50px;
  cursor: pointer;
  transform: rotate(-90deg);
  animation: sunrise 2.5s infinite;
  animation-fill-mode: forwards;
}
.home .home_footer::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 30px;
  background-color: var(--Pr-color);
  bottom: -6px;
  left: -20px;
  transform: rotate(-90deg);
}
@keyframes sunrise {
  0% {
    transform: translateY(100%) rotate(-90deg);
  }
  50% {
    transform: translateY(0) rotate(-90deg);
  }
  100% {
    transform: translateY(100%) rotate(-90deg);
  }
}

@media (max-width: 767px) {
  .home .me {
    position: relative;
    background-image: linear-gradient(var(--Pr-color), transparent);
    border-radius: 12rem 12rem 0 0;
    height: 250px;
    left: calc(50% - 8rem);
    margin-top: 5rem;
    overflow: hidden;
    padding: 0;
    width: 16rem;
  }
  .home .home_body {
    transform: translate(0%, 0%);
    max-width: 90%;
    margin: auto;
    height: 100%;
    position: relative;
    align-items: center;
    z-index: 2;
  }
  .home .home_body h1 {
    text-align: center;
    color: #ffffffc5;
    border-top: 2px solid var(--Pr-color);
    /* border-radius: 20px; */
    /* background-color: #6d6e71d2; */
    padding: 10px;
  }
  .home .home_body p {
    font-size: 12px;
  }
  .home {
    background-image: url("../../assets/image/IMG_8436\ copy2.jpg");
    background-position: 100%;
    background-size: cover;
    overflow: hidden;
    height: 100vh !important;
  }
  .home img {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translate(30%, -12%);
    object-fit: contain;
  }
  .btn-donate {
    font-size: 12px;
  }
  .home .home_footer {
    right: -15px;
    z-index: 5;
    font-size: 12px;
  }
}
