@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
}

.first_Sec {
  background-color: var(--Tre-color);
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* color: var(--Pr-color); */
}
.first_Sec p {
  position: relative;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--Sec-color);
  -webkit-text-stroke: 0.3vw var(--Tre-color);
}
.first_Sec p::before {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: var(--Pr-color) !important;
  -webkit-text-stroke: 0vw var(--Pr-color);
  border-right: 2px solid var(--Pr-color);
  overflow: hidden;
  animation: animate 6s linear infinite;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .first_Sec {
    height: 30vh;
  }
  .first_Sec p {
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 40px;
  }
  .first_Sec .btn-donate {
    font-size: 12px;
  }
}

.Secs_Sec {
  background-color: var(--Tre-color);
  position: relative;
  height: 40vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* color: var(--Pr-color); */
}
.Secs_Sec p {
  position: relative;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--Pr-color);
  -webkit-text-stroke: 0.3vw var(--Tre-color);
}
.Secs_Sec p::before {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: var(--Pr-color) !important;
  -webkit-text-stroke: 0vw var(--Pr-color);
  border-right: 2px solid var(--Pr-color);
  overflow: hidden;
  animation: animate 6s linear infinite;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .Secs_Sec {
    height: 30vh;
  }
  .Secs_Sec p {
    font-size: 18px;
    text-align: center;
    white-space: wrap;
    margin-bottom: 40px;
    -webkit-text-stroke: unset;
  }
  .Secs_Sec p::before {
    border-right: 0;
    animation: none;
  }
  .Secs_Sec .btn-donate {
    font-size: 12px;
  }
}

.ar {
  flex-direction: row-reverse;
}
.arLang {
  font-family: "Cairo";
  font-size: 20px;
}

section {
  position: relative;
  /* top: 60px; */
  min-height: 100vh;
}
.section_container {
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 4rem;
}
.section_subheader {
  font-size: 12px;
  margin-bottom: 1rem;
  font-weight: 600;
  color: var(--Sec-color);
  opacity: 0.8;
  letter-spacing: 2px;
  text-align: center;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.section_header {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--Pr-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_header img {
  width: 8%;
  animation: move-from 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}

@media (max-width: 767px) {
  .section_header h2 {
    font-size: 3.5rem !important;
  }
  .section_container {
    padding: 1rem;
  }
  .section_header img {
    width: 20%;
  }
}

@keyframes move-from {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}

/* From Uiverse.io by Allyhere */
.btn-donate {
  --clr-font-main: hsla(0 0% 20% / 100);
  /* --btn-bg-1: hsla(115 85% 65% / 1); */
  --btn-bg-1: hsla(225 2% 44% /1);
  --btn-bg-2: hsla(180 3% 56% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 0.9em 1.4em;
  min-width: 120px;
  min-height: 44px;
  font-size: var(--size, 1rem);
  font-weight: 500;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(
    325deg,
    var(--btn-bg-2) 0%,
    var(--btn-bg-1) 55%,
    var(--btn-bg-2) 90%
  );
  border: none;
  outline: none;
  border-radius: var(--radii);
  color: var(--Sec-color);
  box-shadow: 0px 0px 20px rgba(109, 110, 113, 0.5),
    0px 5px 5px -1px rgba(109, 100, 113, 0.25),
    inset 4px 4px 8px rgba(152, 153, 158, 0.5),
    inset -4px -4px 8px rgba(93, 97, 107, 0.35);
}

.btn-donate:hover {
  background-position: right top;
}

.btn-donate:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  min-height: 100vh;
  background-color: var(--Tre-color);
  /* background-image: url("./assets/image/IMG_4891.JPG"); */
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
}
.video-responsive::bexfore {
  background-color: var(--Tre-color);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 0;
}
.video-responsive h1 {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--Pr-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0;
}
.video-responsive p {
  max-width: 800px;
  text-align: center;
  margin: auto;
  text-transform: capitalize;
  color: var(--Sec-color);
  letter-spacing: 2px;
  font-size: 12px;
  margin: 10px auto;
}

.video-responsive video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 60%;
  width: 85%;
  border-radius: 20px;
  /* background-color: var(--Pr-color); */
}
@media (max-width: 767px) {
  .video-responsive{
    min-height: 90vh;
  }
  .video-responsive p {
    max-width: 400px;
    font-size: 10px;
  }
  .video-responsive video {
    width: 100%;
    height: 70%;
  }
}
