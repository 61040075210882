.footer {
  position: relative;
  /* top: 120px; */
  max-width: 100%;
  font-weight: 500;
  background-color: var(--Pr-color);
  text-align: center;
  letter-spacing: 5px;
  color: var(--Tre-color);
}
@media (max-width: 767px) {
  .footer {
    font-size: 16px;
    line-height: 2;
  }
}
